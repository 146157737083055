<template>
    <footer v-if="config.header" class="bg-light border-top shadow mt-5">
        <div class="container-wide py-5">
            <AdPlaceholder phkey="footer" class="mb-3" />
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4 small">
                    <p class="h6 mb-2">
                        <T>contact.authors</T><T>quotation.colon</T>
                    </p>
                    <Authors />
                </div>

                <div class="col-12 col-md-6 col-lg-4 small">
                    <p class="h6 mb-2">
                        <T>footer.links</T><T>quotation.colon</T>
                    </p>
                    <ul class="list-unstyled mb-4">
                        <template v-for="(groupLinks, group) in links">
                            <li v-if="$t(`contact.groups.${group}`)">
                                <strong><T>contact.groups.{{ group }}</T><T>quotation.colon</T></strong>
                            </li>
                            <li v-for="link in groupLinks" :key="link.url" class="mb-2">
                                <a :href="link.url" target="_blank" rel="me">
                                    <Icon :v="link.icon" :set="link.iconSet || 'l'" />
                                    <span v-for="lang in link.lang || []" class="badge bg-light text-dark border">{{ lang }}</span>
                                    {{ link.headline }}
                                </a>
                            </li>
                        </template>
                    </ul>

                    <p class="h6 mb-2">
                        <T>contact.contribute.header</T><T>quotation.colon</T>
                    </p>
                    <ul class="list-unstyled mb-4">
                        <li v-if="!config.disableTranslationProposals" class="mb-2">
                            <a href="#" @click.prevent="translationMode">
                                <Icon v="language" />
                                <T>translationMode.action</T>
                            </a>
                        </li>
                        <li class="mb-2">
                            <a href="https://en.pronouns.page/new-version">
                                <Icon v="globe-africa" />
                                <T>localise.short</T>
                            </a>
                        </li>
                        <li class="mb-2">
                            <a href="https://gitlab.com/PronounsPage/PronounsPage/-/boards">
                                <Icon v="code-merge" />
                                <T>contact.contribute.technical.footer</T>
                            </a>
                        </li>
                        <li class="mb-2">
                            <nuxt-link to="/design">
                                <Icon v="palette" />
                                <T>contact.contribute.design.header</T>
                            </nuxt-link>
                        </li>
                        <li v-if="config.ads && config.ads.enabled && $isGranted()" class="mb-2">
                            <a href="#" @click.prevent="toggleAds">
                                <Icon v="ad" />
                                [admins]
                                <span v-if="adsVisible">Disable ads</span>
                                <span v-else>Enable ads</span>
                            </a>
                        </li>
                        <li v-if="config.ads && config.ads.enabled && $isGranted('*')" class="mb-2">
                            <a href="#" @click.prevent="store.toggleAdPlaceholdersVisible()">
                                <Icon v="ad" />
                                [admins]
                                Toggle ad placeholder visibility
                            </a>
                        </li>
                    </ul>

                    <!--
                <p class="h6 mb-2">
                    <T>support.header</T><T>quotation.colon</T>
                </p>
                <ul class="list-unstyled mb-4">
                    <li v-for="link in supportLinks" :key="link.url" class="mb-2">
                        <a :href="link.url" target="_blank" rel="me">
                            <Icon :v="link.icon" :set="link.iconSet || 'l'"/>
                            <span v-for="lang in link.lang || []" class="badge bg-light text-dark border">{{lang}}</span>
                            {{link.headline}}
                        </a>
                    </li>
                </ul>
                -->

                    <div class="mt-2 mb-4 text-center">
                        <ModeSwitch />
                    </div>

                    <p class="small text-muted text-center">
                        <T>footer.version</T>
                        <VersionCommitLink :hash="versionFrontend" />
                        /
                        <Spinner v-if="versionBackend.status.value !== 'success'" />
                        <VersionCommitLink v-else :hash="versionBackend.data.value" />
                    </p>

                <!--
                <p class="h6 mb-2">
                    <T>share</T><T>quotation.colon</T>
                </p>
                <div class="my-2">
                    <Share nolabel/>
                </div>
                -->
                </div>

                <div class="col-12 col-md-6 col-lg-4 small">
                    <p class="h6 mb-2">
                        <T>footer.legal</T><T>quotation.colon</T>
                    </p>
                    <p v-if="$te('footer.ageLimit')">
                        <Icon v="exclamation-triangle" />
                        <T>footer.ageLimit</T>
                    </p>
                    <ul class="list-unstyled mb-4">
                        <li class="mb-2">
                            <nuxt-link :to="`/${config.user.termsRoute}`">
                                <Icon v="gavel" />
                                <T>terms.header</T>
                            </nuxt-link>
                        </li>
                        <li class="mb-2">
                            <nuxt-link :to="`/${config.user.privacyRoute}`">
                                <Icon v="user-secret" />
                                <T>privacy.header</T>
                            </nuxt-link>
                        </li>
                        <li class="mb-2">
                            <Icon v="gavel" />
                            <T>footer.license</T>
                        </li>
                        <li class="mb-2">
                            <Icon v="tools" />
                            <T>footer.using</T>
                        </li>
                    </ul>

                    <p class="h6 mb-2">
                        <T>footer.technical</T><T>quotation.colon</T>
                    </p>
                    <ul class="list-unstyled mb-4">
                        <li class="mb-2">
                            <nuxt-link v-if="config.api !== null" to="/api">
                                <Icon v="laptop-code" />
                                <T>api.header</T>
                            </nuxt-link>
                            <LocaleLink v-else locale="en" link="/api">
                                <Icon v="laptop-code" />
                                <T>api.header</T>
                            </LocaleLink>
                        </li>
                        <li class="mb-2">
                            <a href="https://gitlab.com/PronounsPage/PronounsPage" target="_blank" rel="noopener">
                                <Icon v="gitlab" set="b" />
                                <T>footer.source</T>
                            </a>
                        </li>
                        <li class="mb-2">
                            <a href="https://avris.it" target="_blank" rel="noopener">
                                <Icon v="avris.svg" />
                                avris.it
                            </a>
                        </li>
                    </ul>

                    <p class="h6 mb-2">
                        <T>footer.sibling</T><T>quotation.colon</T>
                    </p>
                    <Siblings />

                    <Stats />
                </div>
            </div>
            <EasterEgg />
            <SupportBanners class="my-3" />
            <!-- <SafariWarning /> -->
        </div>
    </footer>
    <div v-else>
        <a v-for="link in links" :key="link.url" :href="link.url" target="_blank" rel="me">&nbsp;</a>
        <!--<a v-for="link in supportLinks" :key="link.url"  :href="link.url" target="_blank" rel="me">&nbsp;</a>-->
    </div>
</template>

<script>
import { useFetch } from 'nuxt/app';
import { getContactLinks, getSocialLinks, getSupportLinks } from '../src/contact.js';
import { sessionCookieSetting } from '../src/cookieSettings.ts';
import { groupBy } from '../src/helpers.ts';
import useConfig from '../composables/useConfig.ts';
import useDialogue from '../composables/useDialogue.ts';
import { useMainStore } from '../store/index.ts';

export default {
    setup() {
        const translationModeVisibleCookie = useCookie('translationModeVisible', sessionCookieSetting);
        const versionBackend = useFetch('/api/version', { server: false, lazy: true });
        return {
            config: useConfig(),
            store: useMainStore(),
            dialogue: useDialogue(),
            translationModeVisibleCookie,
            versionBackend,
        };
    },
    data() {
        return {
            links: groupBy(
                [...getContactLinks(this.config, this.$translator), ...getSocialLinks(this.config)],
                (l) => l.group,
            ),
            supportLinks: [...getSupportLinks(this.$translator)],
            versionFrontend: this.$config.public.version,
            adsVisible: false,
        };
    },
    async mounted() {
        this.adsVisible = parseInt(localStorage.getItem('adsVisible') || '0') === 1;
    },
    methods: {
        async translationMode() {
            if (!this.$user()) {
                await this.dialogue.alert(this.$t('translationMode.logIn'));
                return;
            }
            await this.dialogue.alert(this.$t('translationMode.welcome'));
            this.store.showTranslationMode();
            this.translationModeVisibleCookie = 'true';
        },
        async toggleAds() {
            if (!this.$isGranted()) {
                return;
            }
            localStorage.setItem('adsVisible', this.adsVisible ? '0' : '1');
            window.location.reload();
        },
    },
};
</script>
