<template>
    <a v-if="isExternal" :href="to" target="_blank" rel="noopener" :class="$attrs.class"><slot></slot></a>
    <nuxt-link v-else :to="to">
        <slot></slot>
    </nuxt-link>
</template>

<script>
export default {
    props: {
        to: { required: true, type: String },
    },
    computed: {
        isExternal() {
            return this.to.startsWith('https://') || this.to.startsWith('http://');
        },
    },
};
</script>
