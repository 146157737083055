<template>
    <div
        v-if="translationModeVisible && $user() && !config.disableTranslationProposals"
        :class="['scroll-btn', 'd-print-none', 'd-flex', 'align-items-center', config.ads && config.ads.enabled ? 'higher' : '']"
    >
        <template v-if="translationMode">
            <button class="btn btn-info btn-sm m-1 px-3 py-1 d-flex justify-content-center align-items-center" @click="showChanges">
                <small><T>translationMode.changes</T><T>quotation.colon</T> {{ changesCount }}</small>
            </button>
            <div v-if="changesCount" @click.prevent="commitChanges">
                <SquareButton link="#" colour="success" :title="$t('translationMode.commit')">
                    <Icon v="check-circle" />
                </SquareButton>
            </div>
            <div v-if="changesCount" @click.prevent="revertChanges">
                <SquareButton link="#" colour="danger" :title="$t('translationMode.revert')">
                    <Icon v="times-circle" />
                </SquareButton>
            </div>
            <div @click.prevent="pause">
                <SquareButton link="#" colour="info" :title="$t('translationMode.header')">
                    <Icon v="pause-circle" />
                </SquareButton>
            </div>
        </template>
        <div v-else @click.prevent="startTranslating">
            <SquareButton link="#" colour="info" :title="$t('translationMode.header')">
                <Icon v="language" />
            </SquareButton>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import Suml from 'suml';
import useConfig from '../composables/useConfig.ts';
import useDialogue from '../composables/useDialogue.ts';
import { useMainStore } from '../store/index.ts';

export default {
    setup() {
        const translationsCookie = useCookie('translations');
        return {
            config: useConfig(),
            store: useMainStore(),
            dialogue: useDialogue(),
            translationsCookie,
        };
    },
    data() {
        return {
        };
    },
    mounted() {
        if (this.$isGranted('translations')) {
            this.store.showTranslationMode();
        }
    },
    methods: {
        startTranslating() {
            this.store.translationInit();
        },
        async commitChanges() {
            await this.dialogue.confirm(`Do you want to commit ${this.changesCount} changes?`, 'success');
            await this.dialogue.postWithAlertOnError('/api/translations/propose', {
                changes: this.translationChanges,
            });
            this.store.translationCommit();
            this.translationsCookie = null;

            setTimeout(
                () => this.dialogue.alert({ header: 'Your translation proposals were saved', message: 'Thank you for contributing!' }, 'success'),
                500,
            );
        },
        async revertChanges() {
            if (this.changesCount) {
                await this.dialogue.confirm(`Do you want to revert ${this.changesCount} changes?`, 'danger');
            }
            this.store.translationAbort();
            this.translationsCookie = null;
        },
        async showChanges() {
            await this.dialogue.alert({
                header: 'Changes overview',
                message: `<pre>${new Suml().dump(this.translationChanges)}</pre>`,
                margin: false,
                size: 'lg',
            }, 'info');
        },
        async pause() {
            this.store.translationPause();
        },
    },
    computed: {
        ...mapState(useMainStore, [
            'translationModeVisible',
            'translationMode',
            'translationChanges',
        ]),
        changesCount() {
            return Object.keys(this.translationChanges).length;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "assets/variables";

    .scroll-btn {
        position: fixed;
        bottom: 2 * $spacer + $square-button-size;
        right: $spacer;
        z-index: 1030;
    }

    @include media-breakpoint-down('lg', $grid-breakpoints) {
        .higher {
            bottom: 4 * $spacer + $square-button-size;
        }
    }
    @include media-breakpoint-up('lg', $grid-breakpoints) {
        .higher {
            z-index: 100001;
            bottom: 6 * $spacer + $square-button-size;
        }
    }
</style>
